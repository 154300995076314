/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'

const UspWrapper = styled.div`
  max-width: 2000px;

  @media (max-width: 575px) {
    width: 100%;
  }
`

const Usp = styled.div`
  & h2 {
    font-size: ${({ theme }) => theme.font.size.big};
    font-weight: ${({ theme }) => theme.font.weight.extraLight};
    margin-bottom: 0;

    @media (max-width: 767px) {
      font-size: ${({ theme }) => theme.font.size.medium};
    }
  }

  &:nth-child(1) {
    background-color: ${({ theme }) => theme.color.dark};
    & h2 {
      color: ${({ theme }) => theme.color.light};
    }
  }

  &:nth-child(2) {
    background-color: ${({ theme }) => theme.color.grey};
    & h2 {
      color: ${({ theme }) => theme.color.light};
    }
  }

  &:nth-child(3) {
    background-color: ${({ theme }) => theme.color.secondary};
    & h2 {
      color: ${({ theme }) => theme.color.light};
    }
  }

  &:nth-child(4) {
    background-color: ${({ theme }) => theme.color.primary};
    & h2 {
      color: ${({ theme }) => theme.color.dark};
    }
  }

  &:nth-child(5) {
    background-color: ${({ theme }) => theme.color.primaryLight};
    & h2 {
      color: ${({ theme }) => theme.color.dark};
    }
  }

  @media (min-width: 1440px) {
    min-width: 290px;
  }

  @media (min-width: 992px) {
    padding: 3rem;
  }

  @media (max-width: 991px) {
    padding: 1rem;
  }
`

interface UspsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Usps
}

const Usps: React.FC<UspsProps> = ({ fields }) => (
  <section className="d-flex justify-content-center" id="usps">
    <UspWrapper className="d-flex flex-column flex-sm-row">
      {fields.usps?.map((usp, index) => (
        <Usp
          key={index}
          className="d-flex align-items-center justify-content-center"
        >
          <h2 className="text-center">{usp?.description}</h2>
        </Usp>
      ))}
    </UspWrapper>
  </section>
)

export default Usps
